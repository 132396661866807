<template>
    <div class="surface-0 flex align-items-center justify-content-center min-w-screen overflow-hidden"  style="min-height: calc(100vh - 50px)">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img :src="'images/heysens-logo.svg'" alt="HeySens logo" class="mb-5"
                    style="width:81px; height:60px;">
            </div>
            <div class="col-12 xl:col-6"
                style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4"
                    style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <div class="text-900 text-3xl font-medium mb-3">Welcome to HeySens!</div>
                        <span class="text-600 font-medium">Sign in to continue</span>
                    </div>

                    <div class="w-full md:w-10 mx-auto">
                        <label for="username" class="block text-900 text-xl font-medium mb-2">用户名</label>
                        <InputText id="username" v-model="username" type="text" class="w-full mb-3" placeholder="用户名"
                            style="padding:1rem;" @keyup.enter="handleLogin" />

                        <label for="password" class="block text-900 font-medium text-xl mb-2">密码</label>
                        <Password id="password" v-model="password" placeholder="密码" :toggleMask="false"
                            class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" :feedback="false" @keyup.enter="handleLogin"></Password>

                        <Button label="登  录" class="w-full p-3 text-xl" @click.prevent="handleLogin"></button>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="layout-footer">
        <img style='display:block; width:20px;height:20px;margin-right: 5px;' id='base64image'
       src='data:image/png;base64,UklGRhACAABXRUJQVlA4WAoAAAAQAAAAEwAAEwAAQUxQSJUAAAABcFtr25q8J1UGoEtNxQqaDtuAwwBUrMAAlJTZAKd094ra3d3d+b8VImIC8FuwlitWAcyi+/x8Pp9nt4mBfzLyf4Q0S5D/IT8/mQeKL+WT+CWkKTE5YHuS7YBEk4A+rQrsaS0gQHMCeVoauNJmgJkmAjCv2GYiAIgDtpHyK/Qkur54546lbee+AJlFKpVGg1LEoeMAAABWUDggVAEAAFAHAJ0BKhQAFAA+kUCaSaWjoiEoCqiwEglsDbtEOVg9wX1zhDNpzGiEBhSG6MepnvL/k5EyQEmHAxDvSQ3oYJNv7QAA/NWc6aeG/hZqPzBipwkl52VowM1b3FAVhM9WTIz88F+KvXGsqswlz/ty+//KnUvlXlOQVsnfpPf+9f5dY6iPBnwBE61YfHsuhDuGPlPhBZvBLbQ+6UwP6ivjRYRqTyqZ10VyeHYNzM/x7VrdjoEXi/7kb0wlLAyMSbtlcZHZBJw1h5ucCVed8J4k4Cfuz/EFPuPdp+Mg+b28XW016u8/eGMH9sgfJs+F7ZdbGXeDTtc8maqLhQyS7eCH0s1Ted/TvVlKYCdZVVvqL/ok/1BO2CRvxMbzZZ9tmd4+ND2hqyPe5L0dsrrmVUA+BlLNwLV1avV8Bu160968vb5WioFCnKrhmxGrO1NHD11dN24AAAA=' />
        <a href="https://beian.miit.gov.cn/" style="color:gray" target="_blank">粤ICP备2022095255号-2</a>
	</div>
    <Toast/>
</template>

<script>
import { validUsername } from "@/utils/validate";

export default {
    name: "Login",
    data() {        
        return {
            username: "",
            password: "",
            loading: false,
            checked: false,
            passwordType: "password",
            redirect: undefined,
            checkVisible: false,
            accArr: [],
            radio: ''
        };
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                console.log(route.query)
                this.redirect = route.query && route.query.redirect;
                console.log(this.redirect)
            },

            immediate: true,
        },
    },
    methods: {
        handleLogin() {
            this.loading = true
            this.$http('/api/admin/login/login', {
                username: this.username,
                password: this.password
            }).then(res => {
                if (res.code == 200) {
                    if (res.data.length) {                        

                        this.accArr = res.data
                        this.loding = false
                        this.checkVisible = true
                    } else {
                        if (validUsername(this.username)
                            && this.password.length >= 6) {
                            this.loading = true;
                            this.$store
                                .dispatch("user/login", {
                                    username: this.username,
                                    password: this.password,
                                })
                                .then(() => {
                                    this.$router.push({ path: this.redirect || "/" });
                                    this.loading = false;
                                })
                                .catch((res) => {
                                    console.log(res)
                                    this.loading = false;
                                });
                        } else {
                            this.$toast.add({ severity: 'error', summary: '发生错误', detail: '提交错误', life: 2000 });
                            return false;
                        }
                    }
                } else {
                    this.$toast.add({ severity: 'error', summary: '发生错误', detail: res.msg, life: 2000 });
                    this.loding = false
                }
            })
        },
        login() {
            console.log(this.radio)
            let item = this.accArr[this.radio]
            console.log(item)
            this.$refs.loginForm.validate((valid) => {
                console.log(valid)
                if (valid) {
                    this.loading = true;
                    this.$store
                        .dispatch("user/login", {
                            username: this.loginForm.username,
                            password: this.loginForm.password,
                            enterprise_pid: item.id,
                            us: item.us
                        })
                        .then(() => {
                            console.log('this.redirect', this.redirect)
                            this.$router.push({ path: this.redirect || "/" });
                            this.loading = false;
                        })
                        .catch((res) => {
                            console.log(res)
                            this.loading = false;
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    }
}
</script>

<style scoped>
.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}
</style>